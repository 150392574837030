import {
  Client,
  IMessage,
  StompHeaders,
  StompSubscription,
} from '@stomp/stompjs';

import { StompBrokerInfo } from 'src/types/AzarUser';

import { ControlledPromise } from './controlled-promise';
import {
  createWebclientConnection,
  // closeChannel,
  sendMessage,
} from './websocket';

/**
 * generators
 */

/**
 * stompJS client를 Init하는 Generator입니다.
 *
 * @param stompBrokerInfo `LoginAPI`의 response가 `connectMediaBroker.request`의 payload로 넘어옵니다.
 */
export class StompClient {
  private stomp?: Client;

  private subscriptions: Record<string, StompSubscription> = {};

  private initPromise: ControlledPromise<void>;

  constructor(
    stompBrokerInfo: StompBrokerInfo,
    {
      onConnect,
      onError,
    }: {
      onConnect?: () => void;
      onError?: () => void;
    } = {}
  ) {
    this.initPromise = new ControlledPromise();
    this.stomp = createWebclientConnection(
      stompBrokerInfo,
      () => {
        if (onConnect) {
          onConnect();
        }
        this.initPromise.resolve();
      },
      () => {
        if (onError) {
          onError();
        }
        this.initPromise.reject(new Error('Network Error'));
      }
    );
  }

  close() {
    try {
      this.stomp?.deactivate();
      this.stomp = undefined;
    } catch (_error) {
      // Discard Error
    }
  }

  private async checkWebsocket() {
    try {
      await this.initPromise.promise;
    } catch (e) {
      return true;
    }

    // initPromise 가 resolve된 시점엔 readyState가 1(CONNECTING)임이 보장되지만
    // CONNECTING된 이후 stomp client 내 websocket 에서 에러가 발생하여
    // readyState가 변경될 수 있어서 추가적인 검사 진행
    // 대부분 이 케이스는 연결이 끊어지고 난 다음이어서, 함수 call 정상 종료목적
    if (this.stomp?.webSocket.readyState !== 1) {
      return true;
    }
    return false;
  }

  public async send(payload: {
    message: string;
    channelId: string;
    clientId: string;
  }) {
    if (await this.checkWebsocket()) {
      return;
    }
    const { message, channelId, clientId } = payload;
    if (!this.stomp) {
      return;
    }
    sendMessage(this.stomp, channelId, clientId, message);
  }

  public unsubscribe(channelId: string) {
    if (this.subscriptions[channelId]) {
      this.subscriptions[channelId].unsubscribe();
      delete this.subscriptions[channelId];
    }
  }

  public async subscribe({
    channelId,
    onMessage,
    headers,
  }: {
    channelId: string;
    onMessage: (message: IMessage) => void;
    headers?: StompHeaders;
  }) {
    if (await this.checkWebsocket()) {
      return;
    }
    if (!this.stomp) {
      return;
    }
    const subscription = this.stomp.subscribe(channelId, onMessage, headers);
    this.subscriptions = {
      ...this.subscriptions,
      [channelId]: subscription,
    };
    return subscription;
  }
}
