import { FC } from 'react';
import Image, { ImageProps } from 'next/image';
import { optimizedImageLoader } from 'src/utils/imageUtils';
interface OptimizeImageProps extends Omit<ImageProps, 'src' | 'loader'> {
  src: string;
  basePath?: string;
}
const OptimizeImage: FC<OptimizeImageProps> = ({
  src,
  alt,
  width,
  height,
  sizes,
  ...props
}) => {
  return <Image loader={e => optimizedImageLoader({
    src,
    width: e.width,
    sizes
  })} src={src} alt={alt} width={width} height={height} loading='lazy' {...props} data-sentry-element="Image" data-sentry-component="OptimizeImage" data-sentry-source-file="index.tsx" />;
};
export default OptimizeImage;