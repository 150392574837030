import { FC, ReactElement } from 'react';
import * as Sentry from '@sentry/nextjs';

import { AxiosRequestConfig } from 'axios';
import { atom } from 'jotai';

import {
  loginWithPhoneNumberAtom,
  showInduceRegisterModalAtom,
} from 'src/stores/auth/atoms';
import {
  featureSettingParamsFromOptionalConsentsAtom,
  LoginModalType,
  loginModalTypeAtom,
  nextStepAtom as nextRegisterStepAtom,
} from 'src/stores/register/atoms';

import { matchEmailAPI } from './apis';
import { atomWithReset, RESET } from 'jotai/utils';
import {
  DeviceTransferMatchEmailErrorCode,
  deviceTransferMatchEmailErrorCodeTranslationMap,
  VerifyAccountResponse,
  VerifyPreparation,
} from './types';
import getDeviceId from 'src/utils/device/id';
import SmsVerificationPhoneNumberStep from 'src/components/LoginModal/SmsVerification/PhoneNumberStep';
import SmsVerificationVerifyCodeStep from 'src/components/LoginModal/SmsVerification/VerifyCodeStep';
import TermAgreeStep from 'src/components/LoginModal/TermAgreeStep';
import RegisterBirthdayStep from 'src/components/LoginModal/Register/BirthdayStep';
import RegisterGenderStep from 'src/components/LoginModal/Register/GenderStep';
import { isAxiosError } from 'src/utils/error';
import { ErrorAlertMessage, ErrorResponse } from 'src/utils/api';

interface LoginRetryData {
  config: AxiosRequestConfig;
}

// 전화번호 로그인 중 회원가입이 필요한 경우 step
export const PhoneNumberRegisterSteps = [
  RegisterBirthdayStep,
  RegisterGenderStep,
];

export const phoneNumberLoginStepNumberAtom = atomWithReset(1);

export const loginRetryDataAtom = atom<LoginRetryData | null>(null);

// TODO: ModalType을 바꾸지 않고, login과정에 step을 추가하는 형태로 개선 검토
export const retryWithSmsVerificationAtom = atom(
  null,
  (get, set, params: LoginRetryData) => {
    set(loginModalTypeAtom, LoginModalType.PHONE_NUMBER_LOGIN);
    set(phoneNumberLoginStepNumberAtom, 2);
    set(loginRetryDataAtom, params);
  }
);

export const SmsVerificationSteps = [
  SmsVerificationPhoneNumberStep,
  SmsVerificationVerifyCodeStep,
];

const PhoneNumberLoginSteps = [TermAgreeStep, ...SmsVerificationSteps];

export const phoneNumberLoginStepsAtom = atomWithReset([
  ...PhoneNumberLoginSteps,
]);

export const errorMessageAtom = atom<undefined | string>('');
const initSmsPhoneNumberValue = {
  phone: '',
  verifyPreparation: { countryName: undefined, countryPrefixNumber: undefined },
  errorMessage: '',
};
export const smsPhoneNumberAtom = atomWithReset<{
  phone: string;
  verifyPreparation: VerifyPreparation;
  errorMessage?: string;
}>(initSmsPhoneNumberValue);

const initSmsVerificationValue = {
  verifyCode: '',
  formattedPhoneNumber: '',
  errorMessage: '',
};
export const smsVerificationAtom = atom<{
  verifyCode: string;
  formattedPhoneNumber: string;
  errorMessage?: string;
  isError?: boolean;
}>(initSmsVerificationValue);

export const nextSmsVerificationStepAtom = atom(null, (get, set) => {
  const loginModalType = get(loginModalTypeAtom);

  switch (loginModalType) {
    case LoginModalType.SOCIAL_REGISTER:
      set(nextRegisterStepAtom);
      break;
    case LoginModalType.PHONE_NUMBER_LOGIN: {
      const currentStepNumber = get(phoneNumberLoginStepNumberAtom);
      if (currentStepNumber === get(phoneNumberLoginStepsAtom).length) {
        Sentry.captureMessage('nextSmsVerificationStep called in last step', {
          extra: { loginModalType, stepNumber: currentStepNumber },
        });
        return;
      }
      set(phoneNumberLoginStepNumberAtom, currentStepNumber + 1);
      break;
    }
  }
});

export const smsVerificationResponseAtom = atom<VerifyAccountResponse | null>(
  null
);

const initEmailVerificationValue = {
  email: '',
};
export const emailVerificationAtom = atomWithReset(initEmailVerificationValue);

const initPhoneNumberLoginEmailValue = {
  email: '',
  errorMessage: '',
};
export const phoneNumberLoginEmailAtom = atom(initPhoneNumberLoginEmailValue);

export const phoneNumberLoginBackStepAtom = atom(null, (get, set) => {
  switch (get(phoneNumberLoginStepNumberAtom)) {
    case 1:
      set(loginModalTypeAtom, LoginModalType.LOGIN);
      break;
    case 2:
      set(smsPhoneNumberAtom, initSmsPhoneNumberValue);
      break;
    case 3:
      set(smsVerificationAtom, initSmsVerificationValue);
      break;
    case 4:
      set(phoneNumberLoginEmailAtom, initPhoneNumberLoginEmailValue);
  }
  if (get(phoneNumberLoginStepNumberAtom) > 1) {
    set(
      phoneNumberLoginStepNumberAtom,
      get(phoneNumberLoginStepNumberAtom) - 1
    );
  }
});

const resetLoginAtom = atom(null, (_get, set) => {
  set(smsPhoneNumberAtom, initSmsPhoneNumberValue);
  set(smsVerificationAtom, initSmsVerificationValue);
  set(phoneNumberLoginEmailAtom, initPhoneNumberLoginEmailValue);
  set(phoneNumberLoginStepNumberAtom, RESET);
  set(emailVerificationAtom, RESET);
});

export const isRemoveOldDeviceUserDetailsAtom = atom(false);

export const verifyErrorMessages: {
  [key: string]: string;
} = { VERIFICATION_FAILED: 'SIGNUP_PHONE_VERIFICATION_FAIL' };

export const loginAfterMatchingEmailAtom = atom(null, async (get, set) => {
  const smsVerificationResponse = get(smsVerificationResponseAtom);
  if (!smsVerificationResponse) {
    Sentry.captureMessage(
      'loginAfterMatchingEmailAtom called without smsVerificationResponse'
    );
    return;
  }
  const { phoneId, phoneToken } = smsVerificationResponse;

  try {
    await matchEmailAPI({
      email: get(phoneNumberLoginEmailAtom).email,
      phoneId,
      phoneToken,
      deviceId: getDeviceId(),
    });
    await set(loginWithPhoneNumberAtom);
    set(featureSettingParamsFromOptionalConsentsAtom, RESET);
  } catch (error) {
    if (!isAxiosError<ErrorResponse<ErrorAlertMessage>>(error)) {
      Sentry.captureException(error);
      return;
    }
    const { response } = error;
    set(phoneNumberLoginEmailAtom, {
      ...get(phoneNumberLoginEmailAtom),
      errorMessage:
        deviceTransferMatchEmailErrorCodeTranslationMap[
          response?.data.error.code as DeviceTransferMatchEmailErrorCode
        ] ||
        deviceTransferMatchEmailErrorCodeTranslationMap.VERIFICATION_FAILED,
    });
  }
});

export const loginModalHeaderAtom = atom<{
  component?: ReactElement;
  onBack?: () => void;
} | void>(undefined);

export const exitLoginModalAtom = atom(null, (get, set) => {
  set(loginModalTypeAtom, LoginModalType.LOGIN);
  set(resetLoginAtom);

  // 가입 단계에서 이탈 시 2초 후 Bounce Rate 모달 노출
  set(showInduceRegisterModalAtom);
});

export const addStepsOnPhoneNumberLoginStepsAtom = atom(
  null,
  (get, set, ...steps: FC[]) => {
    set(phoneNumberLoginStepsAtom, [
      ...get(phoneNumberLoginStepsAtom),
      ...steps,
    ]);
  }
);
