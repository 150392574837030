import { FC, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { ProductNudgingModalContent, ProductNudgingModalHeader, ProductNudgingRefundPolicyText } from 'src/components/ProductNudgingModal';
import { StarterPackageModule, RefundPolicyModule, SingleProductModule } from 'src/types/gemLack';
import { SHOP_ORIGIN, ShopOrigin } from 'src/stores/shop/types';
import { showPaymentPopupAtom } from 'src/stores/shop/atoms';
import { useAtomValue, useSetAtom } from 'jotai';
import { getProductPriceTextWithCurrency, getSaveRateByGemProductComparison } from 'src/utils/payments';
import useProductWithPurchaseLimit from 'src/hooks/useProductWithPurchaseLimit';
import GemAmountTextWithBonusHighlightBase from 'src/components/GemAmountTextWithBonusHighlight';
import IcCheckBase from 'src/assets/images/icCheck.svg';
import IcHistoryBase from 'src/assets/images/icHistory.svg';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import useSelectedTab from 'src/hooks/useSelectedTab';
import useEvent from 'src/hooks/useEvent';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { statusAtom } from 'src/stores/match/atoms';
import { STATUS } from 'src/types/Match';
import { useRouter } from 'next/router';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
const ProductsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;

  ${({
  theme
}) => theme.screenSize.mobile} {
    gap: 10px;
  }
`;
const ProductItemBase = styled.button`
  all: unset;
  background-color: ${({
  theme
}) => theme.color.gray30__dkGray50};
  cursor: pointer;
  padding: 16px;
  border-radius: 16px;
`;
const GemAmountRowCss = css`
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const GemAmountTextWithBonusHighlight = styled(GemAmountTextWithBonusHighlightBase)`
  flex: 1;
`;
const PriceContainerBase = styled.div`
  display: flex;
  padding: 5.5px 16px;
  border-radius: 16px;
  flex-direction: row;
  align-items: center;
`;
const PriceText = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
`;
const StarterPackageItem = styled(ProductItemBase)`
  border: 1px solid ${({
  theme
}) => theme.color.gray800__dkGray900};
  display: flex;
  flex-direction: column;
  gap: 16px;

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }
`;
const StarterPackageRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StarterPackageHeader = styled(StarterPackageRow)`
  gap: 12px;
`;
const StarterPackageIconWrap = styled.div<{
  backgroundColor: string;
}>`
  width: 56px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 12px;
  overflow: hidden;
  background-color: ${({
  backgroundColor
}) => backgroundColor};
`;
const StarterPackageIcon = styled.img`
  width: 100%;
  height: 100%;
`;
const StarterPackageDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const StarterPackageProductName = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: ${({
  theme
}) => theme.color.gray900__dkGray970};
`;
const StarterPackageDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${({
  theme
}) => theme.color.gray800__dkGray900};
`;
const StarterPackageGemAmountRow = styled.div`
  ${GemAmountRowCss};
  gap: 8px;
  background-color: ${({
  theme
}) => theme.color.gray100__dkGray150};
`;
const StarterPackageGemIcon = styled.img`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
`;
const IcCheck = styled(IcCheckBase)`
  width: 14px;
  height: 14px;
  fill: ${({
  theme
}) => theme.color.gray900__dkGray970};
`;
const TimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: ${({
  theme
}) => theme.color.black__dkWhite};
`;
const IcTimer = styled(IcHistoryBase)`
  width: 16px;
  height: 16px;
  fill: currentColor;
`;
const RemainingTimeText = styled.p`
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  transform: translateY(1px);
`;
const StarterPackagePriceContainer = styled(PriceContainerBase)`
  ${({
  theme
}) => theme.isRtl ? 'margin-right' : 'margin-left'} : auto;
  background-color: ${({
  theme
}) => theme.color.gray800__dkGray900};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  ${PriceText} {
    color: ${({
  theme
}) => theme.color.white__dkBlack};
  }
`;
const SaveRateText = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  color: ${({
  theme
}) => theme.color.purple500};
`;
const SingleProductItem = styled(ProductItemBase)`
  ${GemAmountRowCss}
  gap: 12px;
`;
const SingleProductIcon = styled.img`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;
const SingleProductPriceContainer = styled(PriceContainerBase)`
  background-color: ${({
  theme
}) => theme.color.gray200__dkGray200};

  ${PriceText} {
    color: ${({
  theme
}) => theme.color.blackBg__dkWhiteBg};
  }
`;
interface Props {
  description: string;
  starterPackageModule: StarterPackageModule;
  singleProductModule: SingleProductModule;
  refundPolicyModule?: RefundPolicyModule;
  origins: ShopOrigin[];
}
const StarterPackageNudging: FC<Props> = ({
  description,
  singleProductModule,
  starterPackageModule,
  refundPolicyModule,
  origins
}) => {
  const showPaymentPopup = useSetAtom(showPaymentPopupAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const status = useAtomValue(statusAtom);
  const {
    selectedEventTab
  } = useSelectedTab();
  const pushEvent = useEvent();
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const {
    isPurchasable,
    remainingTime
  } = useProductWithPurchaseLimit(starterPackageModule);
  const eventPage = useMemo(() => {
    if (status !== STATUS.INITIAL) return 'finding';
    return router.pathname.includes('host') ? 'room' : 'main';
  }, [router.pathname, status]);
  const starterPackageSaveRate = useMemo(() => {
    return getSaveRateByGemProductComparison(starterPackageModule, singleProductModule);
  }, [starterPackageModule, singleProductModule]);
  const handleProductItemClick = (productModule: StarterPackageModule | SingleProductModule) => {
    pushEvent({
      eventType: EVENT_TYPE.POPUP,
      eventName: EVENT_NAME.STARTER_PACKAGE_POPUP_V1_CLICK_BUTTON,
      eventParams: {
        action_category: 'click',
        tab: selectedEventTab,
        page: eventPage,
        target: 'starterpackage_popup',
        initial_feature: origins,
        button_type: productModule.productId
      }
    });
    showPaymentPopup({
      product: productModule,
      origins,
      originTab: selectedEventTab
    });
    const isContentsMatchNudging = origins.includes(SHOP_ORIGIN.starterPackageNudge) && status === STATUS.CONTENTS_MATCH;

    // 컨텐츠 매치 중에는 결제가 원활히 이루어질 수 있도록 결제 완료 전까지 유지
    if (!isContentsMatchNudging) {
      closeModal(ModalType.STARTER_PACKAGE_NUDGING);
    }
  };
  useEffect(() => {
    pushEvent({
      eventType: EVENT_TYPE.POPUP,
      eventName: EVENT_NAME.STARTER_PACKAGE_POPUP_V1_IMPRESSION,
      eventParams: {
        action_category: 'impression',
        tab: selectedEventTab,
        page: eventPage,
        target: 'starterpackage_popup',
        initial_feature: origins
      }
    });
  }, [eventPage, origins, pushEvent, selectedEventTab]);
  return <>
      <ProductNudgingModalHeader title={t('popup_web_starterpackage_title')} description={description} data-sentry-element="ProductNudgingModalHeader" data-sentry-source-file="StarterPackageNudging.tsx" />
      <ProductNudgingModalContent data-sentry-element="ProductNudgingModalContent" data-sentry-source-file="StarterPackageNudging.tsx">
        <ProductsContainer data-sentry-element="ProductsContainer" data-sentry-source-file="StarterPackageNudging.tsx">
          <StarterPackageItem disabled={!isPurchasable} onClick={() => handleProductItemClick(starterPackageModule)} data-sentry-element="StarterPackageItem" data-sentry-source-file="StarterPackageNudging.tsx">
            <StarterPackageHeader data-sentry-element="StarterPackageHeader" data-sentry-source-file="StarterPackageNudging.tsx">
              <StarterPackageIconWrap backgroundColor={starterPackageModule.content.iconBackgroundColor} data-sentry-element="StarterPackageIconWrap" data-sentry-source-file="StarterPackageNudging.tsx">
                <StarterPackageIcon src={starterPackageModule.iconUrl} alt={`${starterPackageModule.productName} icon`} data-sentry-element="StarterPackageIcon" data-sentry-source-file="StarterPackageNudging.tsx" />
              </StarterPackageIconWrap>
              <StarterPackageDetails data-sentry-element="StarterPackageDetails" data-sentry-source-file="StarterPackageNudging.tsx">
                <StarterPackageProductName data-sentry-element="StarterPackageProductName" data-sentry-source-file="StarterPackageNudging.tsx">
                  {starterPackageModule.productName}
                </StarterPackageProductName>
                <StarterPackageDescription data-sentry-element="StarterPackageDescription" data-sentry-source-file="StarterPackageNudging.tsx">
                  {starterPackageModule.content.description}
                </StarterPackageDescription>
              </StarterPackageDetails>
            </StarterPackageHeader>
            <StarterPackageGemAmountRow data-sentry-element="StarterPackageGemAmountRow" data-sentry-source-file="StarterPackageNudging.tsx">
              <StarterPackageGemIcon src={starterPackageModule.content.gemIconUrl} alt='gem icon' data-sentry-element="StarterPackageGemIcon" data-sentry-source-file="StarterPackageNudging.tsx" />
              <GemAmountTextWithBonusHighlight gemProduct={starterPackageModule} data-sentry-element="GemAmountTextWithBonusHighlight" data-sentry-source-file="StarterPackageNudging.tsx" />
              <IcCheck data-sentry-element="IcCheck" data-sentry-source-file="StarterPackageNudging.tsx" />
            </StarterPackageGemAmountRow>
            <StarterPackageRow data-sentry-element="StarterPackageRow" data-sentry-source-file="StarterPackageNudging.tsx">
              {remainingTime && <TimerContainer>
                  <IcTimer />
                  <RemainingTimeText>{remainingTime.text}</RemainingTimeText>
                </TimerContainer>}
              <StarterPackagePriceContainer data-sentry-element="StarterPackagePriceContainer" data-sentry-source-file="StarterPackageNudging.tsx">
                <SaveRateText data-sentry-element="SaveRateText" data-sentry-source-file="StarterPackageNudging.tsx">
                  {t('web_starterpackage_discount', {
                  percent: starterPackageSaveRate
                })}
                </SaveRateText>
                <PriceText data-sentry-element="PriceText" data-sentry-source-file="StarterPackageNudging.tsx">
                  {getProductPriceTextWithCurrency(starterPackageModule)}
                </PriceText>
              </StarterPackagePriceContainer>
            </StarterPackageRow>
          </StarterPackageItem>
          <SingleProductItem onClick={() => handleProductItemClick(singleProductModule)} data-sentry-element="SingleProductItem" data-sentry-source-file="StarterPackageNudging.tsx">
            <SingleProductIcon src={singleProductModule.iconUrl} alt={`${singleProductModule.productName} icon`} data-sentry-element="SingleProductIcon" data-sentry-source-file="StarterPackageNudging.tsx" />
            <GemAmountTextWithBonusHighlight gemProduct={singleProductModule} data-sentry-element="GemAmountTextWithBonusHighlight" data-sentry-source-file="StarterPackageNudging.tsx" />
            <SingleProductPriceContainer data-sentry-element="SingleProductPriceContainer" data-sentry-source-file="StarterPackageNudging.tsx">
              <PriceText data-sentry-element="PriceText" data-sentry-source-file="StarterPackageNudging.tsx">
                {getProductPriceTextWithCurrency(singleProductModule)}
              </PriceText>
            </SingleProductPriceContainer>
          </SingleProductItem>
        </ProductsContainer>
        {refundPolicyModule && <ProductNudgingRefundPolicyText refundPolicyModule={refundPolicyModule} />}
      </ProductNudgingModalContent>
    </>;
};
export default StarterPackageNudging;