import { useRestMutation } from 'src/hooks/useRestMutation';
import { ReportPopupViewedRequest } from './types';

export enum PopupApiList {
  /**
   * 특정 유형의 팝업을 확인했을 때 이를 서버에 공유하는 API
   */
  ReportPopupViewed = '/api/popups/viewed/',
}

export const useReportPopupViewedMutation = () => {
  return useRestMutation<ReportPopupViewedRequest>({
    method: 'post',
    path: PopupApiList.ReportPopupViewed,
  });
};
