import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import useEvent from 'src/hooks/useEvent';
import useSelectedTab from 'src/hooks/useSelectedTab';
import { showPaymentPopupAtom } from 'src/stores/shop/atoms';
import { SHOP_ORIGIN } from 'src/stores/shop/types';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { PurchasableProduct } from 'src/types/Payments';

const useClickProductItem = (product: PurchasableProduct) => {
  const showPaymentPopup = useSetAtom(showPaymentPopupAtom);
  const { selectedEventTab } = useSelectedTab();
  const pushEvent = useEvent();

  const handleClickProduct = useCallback(() => {
    pushEvent({
      eventType: EVENT_TYPE.SHOP,
      eventName: EVENT_NAME.ANYWHERE__CLICK_SHOP_PRODUCT,
      eventParams: {
        tab: selectedEventTab,
        page: 'anywhere',
        action_category: 'click',
        target: 'shop_product',
        product_id: product.productId,
      },
    });
    showPaymentPopup({
      product,
      origins: [SHOP_ORIGIN.gemShop],
      originTab: selectedEventTab,
    });
  }, [product, pushEvent, selectedEventTab, showPaymentPopup]);

  return {
    handleClickProduct,
  };
};

export default useClickProductItem;
