import styled from '@emotion/styled';

export const PromoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: keep-all;
  justify-content: center;
  margin-bottom: 10px;
  border-radius: 16px;
  padding: 16px;
  background-color: ${({ theme }) => theme.color.gray30__dkGray50};
`;

export const PromoIcon = styled.img`
  width: 22px;
  height: 22px;
  vertical-align: top;
`;

export const PromoTitle = styled.div`
  display: flex;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.gray700__dkGray700};
`;

export const PromotionTextWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  padding-top: 8px;
`;

export const PromoRate = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.color.green500};
`;

export const PromotionRate = styled(PromoRate)`
  margin-top: 2px;
  color: ${({ theme }) => theme.color.purple400};
`;

export const PromotionTotalRate = styled(PromoRate)`
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.black__dkWhite};
  font-weight: 700;
  padding-top: 8px;
`;

export const ProductSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ProductSectionDivider = styled.div`
  margin: 20px 0;
  height: 1px;
  background-color: ${({ theme }) => theme.color.gray100__dkGray150};
`;

export const ProductList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
