import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalBackground } from 'src/components/Modal';
import { ModalFooter } from 'src/components/ModalLayout';
import Button from 'src/components/Button';
import { useSetAtom } from 'jotai';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
import { exitLoginModalAtom } from 'src/stores/phoneNumberLogin/atoms';
import { AuthErrorDesc, AuthErrorImg, AuthErrorMain, AuthErrorModalLayout, AuthErrorTitle } from 'src/components/AuthErrorModal/style';
import styled from '@emotion/styled';
const ModalLayout = styled(AuthErrorModalLayout)`
  height: 600px;
`;
const SMSRestrictedModal: FC = () => {
  const {
    t
  } = useTranslation();
  const closeModal = useSetAtom(closeModalAtom);
  const exitLoginModal = useSetAtom(exitLoginModalAtom);
  const onClose = () => {
    closeModal(ModalType.ERROR);
    exitLoginModal();
  };
  return <ModalBackground data-sentry-element="ModalBackground" data-sentry-component="SMSRestrictedModal" data-sentry-source-file="index.tsx">
      <ModalLayout isDim onClose={onClose} data-sentry-element="ModalLayout" data-sentry-source-file="index.tsx">
        <AuthErrorMain data-sentry-element="AuthErrorMain" data-sentry-source-file="index.tsx">
          <AuthErrorImg src='/images/icons/imgPlaceholder.svg' alt='SMS Restricted Error' data-sentry-element="AuthErrorImg" data-sentry-source-file="index.tsx" />
          <AuthErrorTitle data-sentry-element="AuthErrorTitle" data-sentry-source-file="index.tsx">{t('sms_reg_restricted_title')}</AuthErrorTitle>
          <AuthErrorDesc data-sentry-element="AuthErrorDesc" data-sentry-source-file="index.tsx">{t('sms_reg_restricted_desc')}</AuthErrorDesc>
        </AuthErrorMain>
        <ModalFooter data-sentry-element="ModalFooter" data-sentry-source-file="index.tsx">
          <Button onClick={onClose} id='COMMON__OK' data-sentry-element="Button" data-sentry-source-file="index.tsx">
            {t('COMMON__OK')}
          </Button>
        </ModalFooter>
      </ModalLayout>
    </ModalBackground>;
};
export default SMSRestrictedModal;