import {
  MLFeatureTriggerInference,
  MLInference,
  MLThresholdInference,
} from 'src/stores/ml/types';
import { GENDER } from './register';
import { UserAzarBadge } from 'src/stores/profile/types';

export const maxFramerate = 30;

export interface MatchFilterOption {
  key: string;
  displayName: string;
  paid: boolean;
  gemCost: number;
}

export interface MatchFilter {
  filterOptions: MatchFilterOption[];
  filterId: string;
  consumableCategoryId?: string;
  periodicCategoryId?: string;
  requiredCategoryId?: string;
}

export interface MatchFilterSelection {
  filterId: string;
  optionKey: string;
  optionGemCost: number;
}

export interface DebugStat {
  local: {
    default: string;
    framerate: string;
    'encode time': string;
    localVideoPacketsLost: string;
  };
  remote: {
    default: string;
    framerate: string;
    'jitter ms': string;
    'decode time': string;
    remoteVideoPacketsLost: string;
  };
  connection: {
    local: string;
    remote: string;
    'encoding bitrate': string;
    'transmit bitrate': string;
    'send bandwidth': string;
    rtt: string;
  };
}

/**
 * 매칭 과정에서 현재 유저의 단계
 */
export enum STATUS {
  /**
   * 매치 풀에 등록하기 전 (통칭 미러로 표현하는 UI)
   */
  INITIAL,

  /**
   * 매치 풀에 등록 됐지만 매칭되기 전
   */
  FINDING,

  /**
   * 광고, 넛징 등을 위해 사용하는 컨텐츠 매치를 보여주는 상태
   */
  CONTENTS_MATCH,

  /**
   * 서버에서 매칭된 상태
   */
  CONNECTING,

  /**
   * 실제 유저간 대화가 이루어지는 상태
   */
  MATCHED,
}

export enum ReportMatchMethod {
  IN_MATCH = 'IN_MATCH',
  TIME_MACHINE = 'TIME_MACHINE',
  VIDEO_CALL = 'VIDEO_CALL',
  IN_MATCH_AUTO = 'IN_MATCH_AUTO',
  AUTO_TIMEOUT = 'IN_MATCH_AUTO_TIMEOUT',
  IN_MATCH_ON_BLUR = 'IN_MATCH_ON_BLUR',
}

export interface SignalingInfo {
  channelId: string;
  clientId: string;
}

export interface BonusMatchStatus {
  bonusMatch: boolean; // 이번 매치에 보너스 매치가 적용됨을 의미
  progress: {
    bonusMatchAvailable: boolean; // true 이면 이번 매치는 보너스 매치 적용 가능함을 의미
    currentGauge: number; // 현재 보너스 매치 게이지
    totalGauge: number; // 보너스 매치 당첨까지 걸리는 총 게이지
    remainingTimeMillis: number; // progress 가 유효한 남은 시간
  };
}

export interface MatchConfig {
  signalingInfo: SignalingInfo;
  matchId: string;
  iceConnectionTimeoutSeconds: number | null;
  matchTimeoutSeconds: number | null;
  requestId: string;
  shouldReportMatchMetrics: boolean | null;
  strongProtectionEnabled: boolean | null;
  bonusMatchStatus: BonusMatchStatus;
  shouldReportMatchSummary: boolean; // 다음 매치 요청할 때 해당 매치의 정보를 넘겨줄지 여부
  // updatedItems: any[];
}

export interface PreviousMatchSummary {
  matchId: string;
  chatDurationMs: number;
  finisher: boolean;
  bonusMatch: boolean;
}

export interface UserReportPayload {
  images: string[];
  matchId: string;
  reportType: string;
}

export interface DisconnectPayload {
  reconnect?: boolean;
}

export type MatchBrokerMessage =
  | MatchToLiveRoomMessage
  | ChatMsgMessage
  | MatchInfoMessage
  | AnswerMessage
  | CandidateMessage
  | MatchAcceptedMessage
  | CameraStatusUpdatedMessage
  | SystemMessageInMatchMessage
  | ContentsMatchMessage;

interface CameraStatusUpdatedMessage {
  enabled: boolean;
  matchId: string;
  type: 'cameraStatusUpdated';
}
interface SystemMessageInMatchMessage {
  message: string;
  type: 'systemMessageInMatch';
}

export interface ChatMsgMessage {
  matchId: string;
  originalMessage: string;
  translatedMessage: string;
  messageUid: string;
  type: 'chatMsg';
}

export interface InterestInfo {
  key: string;
  displayName: string;
  normalImageUrl: string | null;
  pressedImageUrl: string | null;
}

interface MatchAsyncCardInfo {
  cardId: string;
  nickname: string;
  location: Location | null;
  profileImageUrl: string;
  interestInfos: null | InterestInfo[];
}

export interface ChoiceInfo {
  onlineProfileIndex: number;
  asyncCardInfos: MatchAsyncCardInfo[];
}

interface TopicInfo {
  topicId: string;
  emoji: string;
  text: string;
}

/**
 * https://git.dev.hpcnt.com/hyperconnect/azar-api/blob/ce164f40ad283ac8ae04233ab7361e434585fc51/src/main/java/com/azarlive/api/event/broker/PeerProfile.java#L370-L417
 */
export interface PeerProfile {
  uid: null;
  loginType: string;
  simpleName: string;
  veiledName: string | null;
  gender: GENDER;
  country: string;
  language: string;
  location: Location | null;
  position: Position | null;
  screenshotAllowed: boolean;
  requestFriendAllowed: boolean;
  highDefinitionVideo: boolean;
  maxVideoBandwidthKbps: number;
  sayHi: string | null;
  sayCompliment: string | null;
  sayAskFriend: string | null;
  thumbnailImageUrl: string;
  profileImageUrl: string;
  profileImageCount: number;
  coverProfileImageUrl: string;
  profileMessage: null;
  profileBio: null;
  textChatSupported: boolean;
  faceDetectionRequired: boolean;
  negotiationTimeoutSeconds: null;
  backgroundInfo: null;
  hasChoice: null;
  displayDelayMs: null;
  confirmedVisualAbuse: boolean | null;
  confirmedToplessAbuse: boolean | null;
  mutualInterest: null;
  interestInfos: InterestInfo[] | null;
  instagramIntegrationInfo: null;
  monitorability: string | null;
  uniqueProfile: null;
  receivedGiftRecently: boolean;
  lightweightGiftPointsInfo: LightweightGiftPointsInfo | null;
  type: string;
  medalIcons: string[] | null;
  availableLanguages: string[] | null;
  profileVideoUrl: null;
  hashtags: string[] | null;
  reputationScore: null;
  topicInfos: TopicInfo[] | null;
  requestReactionAllowed: boolean;
  reputationRating: string | null;
  azarBadge: UserAzarBadge | null;
}

interface LightweightGiftPoint {
  effectType: string;
  count: number;
  text: string;
}

export interface LightweightGiftPointsInfo {
  pointList: LightweightGiftPoint[];
}

export interface Location {
  country: string;
  countryCode: string;
  state: null;
  city: null;
  timeZoneId: string;
  timeZoneOffset: number;
  virtualLocation: null;
  virtualThumbnailUrl: null;
}

export interface Position {
  latitude: number;
  longitude: number;
}

export interface ReportEnabledModelInfo {
  model: string;
  reportIntervalMs: number;
}

export type MatchGender = 'MALE' | 'ALL' | 'FEMALE' | 'BOTH';

export interface MatchOptions {
  PREFERRED_GENDER?: MatchGender;
  PREFERRED_PLATFORM?: string | null;
  MATCH_GROUP?: string;
  ADULT_ONLY?: number;
  MINOR_ONLY?: number;
  MATCH_TAG?: string | null;
}

export interface RandomMatchContext {
  facesOnRequest?: number;
  facesDuringPrevChats?: string[];
  onMeteredNetwork?: boolean;
  lsmim?: number;
  lsmalm?: number;
  blurOn?: number;
  usingVpn?: number;
  usingBackCamera?: number;
  turnProtocol?: number;
  recordDetectResults?: string[];
  bma?: number;
  ca?: boolean;
  deviceAvailableVideoHeight?: number;
  adsPrepared?: number;
}

export interface ConnectState {
  signalingInfo: SignalingInfo | null;
  peerProfile: PeerProfile | null;
  matchId: string;
}

export interface VideoNameObj {
  recordStartTime?: number;
  country?: string;
  language?: string;
  gender?: string;
  menuOpenTime?: number;
  reportType?: string;
  monitorability?: string;
}

export interface MediaBrokerState {
  isConnected: boolean;
}

export interface MatchToLiveRoomMessage {
  type: 'matchToLiveRoom';
}

interface ContentsMatchButtonInfo {
  /**
   * Call to action URL. If this field is not null, the button is active.
   */
  buttonUrl?: string | null;

  /**
   * Text on the call to action button.
   */
  buttonText?: string | null;

  /**
   * Color RGB value of the button.
   */
  buttonColor?: string | null;

  /**
   * Text color RGB value of the button.
   */
  buttonTextColor?: string | null;
}

export interface ContentsMatchMediaInfo {
  mediaType: string;
  contentsUrl: string;
  imageVisibleSeconds?: number;
  repeated?: boolean;
}

interface ContentsProfileInfo {
  /**
   * Profile image URL to be used in content match.
   */
  profileImageUrl: string;

  /**
   * Thumbnail image URL.
   */
  thumbnailImageUrl: string;

  /**
   * Representative name of the profile.
   */
  profileName: string;

  /**
   * Text entered below the profile in team match connection screen and team match screen.
   */
  sponsoredText: string;

  /**
   * Text color for the common text used in the team match connection screen.
   */
  connectingTextColor: string;
}

export interface ContentsMatchMessage {
  type: 'contentsMatchMessage';

  /**
   * Match ID for content match
   */
  contentsMatchId: string;

  /**
   * Media information to display to the user when it's their turn for content match
   */
  contentsMatchMediaInfo: ContentsMatchMediaInfo;

  /**
   * Button used during team match play, either a native or screenArea button type. Nullable.
   */
  nativeButtonInfo?: ContentsMatchButtonInfo | null;

  /**
   * Type of button for screen area. Nullable.
   */
  screenAreaButtonType?: string | null;

  /**
   * List of button information when screenAreaButton is used. Nullable.
   */
  screenAreaButtonInfos?: ContentsMatchButtonInfo[] | null;

  /**
   * Profile information to expose in the opponent's account information during content matching
   */
  contentsProfileInfo: ContentsProfileInfo;

  /**
   * If true, the content cannot be skipped without playing. Nullable.
   */
  skipPrevented?: boolean | null;

  /**
   * Disable exit button and disable swiping to skip during team matches
   */
  enableExit: boolean;

  /**
   * Flags whether to show the sponsored mark on the team match connection screen
   */
  enableSponsored: boolean;

  /**
   * Hides components that obscure the profile image on the team match connection screen
   */
  clearProfile: boolean;

  /**
   * Timeout information for the team match, in ms
   */
  timeoutMs: number;
}

export interface MatchInfoMessage {
  type: 'matchInfo';
  initiator: boolean;
  matchId: string;
  peerProfile: PeerProfile;
  matchVideoHeight: number;
  maxVideoBandwidthKbps: number;
  swipeDisabledDurationMs?: number;
}

export type MatchInfo = Pick<
  MatchInfoMessage,
  'peerProfile' | 'matchId' | 'initiator' | 'swipeDisabledDurationMs'
>;

export interface AnswerMessage {
  type: RTCSdpType;
  sdp: string;
}

export interface CandidateMessage {
  type: 'candidate';
  label: number;
  id: string;
  candidate: string;
}

export interface MatchAcceptedMessage {
  type: 'matchAccepted';
}

export type MobileLayout = 'DEFAULT' | 'HALF';

export interface MLMatchInferences {
  matchId: string;
  models: {
    modelId: string;
    index: number;
    inferences: MLInference[];
  }[];
}

export interface MLMatchSummary {
  matchId: string;
  matchSummaryModelReports: {
    modelId: string;
    inferencesCount: number;
    averageLatencyMs: number;
    usedGPU: boolean;
    usedXNNPACK: boolean;
    suspendPeriodicReport: boolean;
  }[];
  matchSummaryFeatureReports: {
    type: string;
    machineLearningThresholdInference: MLThresholdInference[];
    machineLearningFeatureTriggerInferences: MLFeatureTriggerInference[];
  }[];
}

export interface MLMatchMetrics {
  actions: {
    actedAt: number;
    action: string;
    feature: string;
    modelType: string;
    triggeredAt: number;
  }[];
  matchId: string;
}
