import { eventLoginModalVisitorTriggerAtom } from 'src/stores/event/atoms';
import useLogin from 'src/hooks/useLogin';
import { useSetAtom } from 'jotai';
import { EventLoginModalVisitorTrigger } from 'src/types/Event';

export default function useEventLoginModalVisitorTrigger() {
  const { isLogin } = useLogin();
  const setEventLoginModalVisitorTrigger = useSetAtom(
    eventLoginModalVisitorTriggerAtom
  );

  return (trigger: EventLoginModalVisitorTrigger) => {
    if (!isLogin) {
      setEventLoginModalVisitorTrigger(trigger);
    }
  };
}
