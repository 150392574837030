import { useAtom, useSetAtom } from 'jotai';

import EmailAddressRegisterStep from 'src/components/LoginModal/PhoneNumberLogin/EmailAddressRegisterStep';
import { useRestMutation } from 'src/hooks/useRestMutation';
import {
  loginWithPhoneNumberAtom,
  retryLoginWithPhoneVerifyAtom,
  socialTokenAtom,
} from 'src/stores/auth/atoms';
import { getSignUpSettingsAPI } from 'src/stores/register/apis';
import {
  FALLBACK_SIGNUP_SETTINGS,
  LoginModalType,
  loginModalTypeAtom,
  nextStepAtom,
  registerAtom,
} from 'src/stores/register/atoms';
import {
  PhoneNumberLoginApiList,
  checkAccountExistAPI,
} from 'src/stores/phoneNumberLogin/apis';
import {
  addStepsOnPhoneNumberLoginStepsAtom,
  loginRetryDataAtom,
  smsVerificationAtom,
  smsVerificationResponseAtom,
  verifyErrorMessages,
  PhoneNumberRegisterSteps,
} from 'src/stores/phoneNumberLogin/atoms';
import {
  VerifyAccountRequest,
  VerifyAccountResponse,
} from 'src/stores/phoneNumberLogin/types';
import { UserSettingType } from 'src/stores/user/types';
import { Platform } from 'src/types/Platform';
import { ApiResponse, ErrorAlertMessage, ErrorResponse } from 'src/utils/api';
import { getLanguageCode } from 'src/utils/language';
import { useGetConsents } from 'src/hooks/useGetConsents';

export default function useVerifyCode() {
  const setSocialToken = useSetAtom(socialTokenAtom);
  const addSteps = useSetAtom(addStepsOnPhoneNumberLoginStepsAtom);
  const loginWithPhone = useSetAtom(loginWithPhoneNumberAtom);
  const [smsVerification, setSmsVerification] = useAtom(smsVerificationAtom);
  const setSmsVerificationPhone = useSetAtom(smsVerificationResponseAtom);
  const [loginModalType] = useAtom(loginModalTypeAtom);
  const nextStep = useSetAtom(nextStepAtom);

  const verifyCodeMutation = useRestMutation<
    VerifyAccountRequest,
    ApiResponse<VerifyAccountResponse>,
    ErrorResponse<ErrorAlertMessage>
  >({
    method: 'post',
    path: PhoneNumberLoginApiList.VerificationsValidate,
    options: {
      onSuccess: async ({ data: { result } }) => {
        const isExistAccount = await checkAccountExistAPI(result.phoneId)();

        setSocialToken({
          id: result.phoneId,
          phoneToken: result.phoneToken,
        });

        setSmsVerificationPhone(result);

        if (isExistAccount.data.result) {
          loginWithPhone();
        } else {
          addSteps(EmailAddressRegisterStep, ...PhoneNumberRegisterSteps);
          nextStep();
        }
      },
      onError: (error) => {
        if (error.response?.data.error.code === 'VERIFICATION_FAILED') {
          setSmsVerification({
            ...smsVerification,
            errorMessage:
              verifyErrorMessages[error.response.data.error.code] ||
              'SIGNUP_PHONE_VERIFICATION_FAIL',
          });
        }
      },
    },
  });
  const { formattedPhoneNumber } = smsVerification;
  const [loginRetryData, setLoginRetryData] = useAtom(loginRetryDataAtom);
  const register = useSetAtom(registerAtom);
  const retryLoginWithPhoneVerify = useSetAtom(retryLoginWithPhoneVerifyAtom);
  const { data: consentsData } = useGetConsents();
  const { uiType: consentsUiType } = consentsData?.data?.result || {};

  // 회원가입 중 NotVerifiedException 에러가 발생했을 때 재시도를 위한 로직
  const retryRegister = async (code: string) => {
    const signUpSettingsResponse = await getSignUpSettingsAPI();
    const signUpSettings =
      signUpSettingsResponse.data ?? FALLBACK_SIGNUP_SETTINGS;
    const retryPayload = {
      phoneVerification: {
        verifyCode: code,
        formattedPhoneNumber,
      },
      featureSettings: [
        {
          type: UserSettingType.HIDE_GENDER,
          value: signUpSettings.genderSettings.hideGenderDefaultValue,
        },
      ],
      cmpConsentsAccepted: consentsUiType === 'CONSENT_WEB_A',
    };
    register(retryPayload);
  };

  // 로그인 중 NotVerifiedException 에러가 발생했을 때 재시도를 위한 로직
  const retryLogin = async (code: string) => {
    if (!loginRetryData) return;

    const retryPayload = {
      phoneVerification: {
        verifyCode: code,
        formattedPhoneNumber,
      },
    };
    const originData = JSON.parse(loginRetryData.config.data);
    retryLoginWithPhoneVerify({
      ...loginRetryData.config,
      data: {
        ...originData,
        params: [
          {
            ...originData.params[0],
            ...retryPayload,
          },
        ],
      },
    });
    setLoginRetryData(null);
  };

  const handleVerifyCode = (code: string) => {
    if (loginModalType === LoginModalType.SOCIAL_REGISTER) {
      retryRegister(code);
      return;
    }
    if (loginRetryData) {
      retryLogin(code);
      return;
    }

    verifyCodeMutation.mutate({
      localeInfo: {
        countryCode: null,
        languageCode: getLanguageCode().toLowerCase(),
      },
      phoneVerification: {
        verifyCode: code,
        formattedPhoneNumber,
      },
      platform: Platform.WebClient,
    });
  };
  return {
    handleVerifyCode,
    isLoading: verifyCodeMutation.isLoading,
    isSuccess: verifyCodeMutation.isSuccess,
    isError: verifyCodeMutation.isError,
  };
}
