import { FC } from 'react';
import useEnableRemoteConfig from 'src/hooks/useEnableRemoteConfig';
import { GemProduct } from 'src/types/Payments';
import { getProductCalcGem, getProductPriceTextWithCurrency } from 'src/utils/payments';
import roundNumber from 'src/utils/roundNumber';
import styled from '@emotion/styled';
import { ProductItem, ProductImage, ProductImageWrapper, ProductLabelWrapper, ProductPriceWrap, PriceText, ProductNameText } from './styles';
import color from 'src/styles/color';
import useClickProductItem from './useClickProductItem';
import { useTranslation } from 'react-i18next';
const GemAmountWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
const BonusGemAmountText = styled.p<{
  colorCode: string;
}>`
  font-size: 14px;
  color: ${({
  colorCode
}) => colorCode};
  font-weight: 400;
`;
const OriginalPrice = styled.div`
  text-decoration: line-through;
  font-size: 12px;
  color: ${({
  theme
}) => theme.color.gray600__dkGray500};
`;
const DiscountPriceInfo = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 2px;
`;
const DiscountPercentText = styled.span`
  color: ${({
  theme
}) => theme.color.red500};
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
`;
interface Props {
  gemProduct: GemProduct;
}

/**
 * 결제 수수료 관련 영향으로 웹에서 자동적으로 적용되는 할인율
 */
const WEB_DISCOUNT_RATE = 0.1;
const GemProductItem: FC<Props> = ({
  gemProduct
}) => {
  const enableWebLowPrice = useEnableRemoteConfig('enableWebLowPrice');
  const {
    handleClickProduct
  } = useClickProductItem(gemProduct);
  const {
    t
  } = useTranslation();
  const priceBeforeWebDiscount = roundNumber(gemProduct.price * (1 / (1 - WEB_DISCOUNT_RATE)), 1, 'ceil');
  const gemCount = getProductCalcGem(gemProduct);
  const bonusGemAmounts = [{
    key: 'bonus',
    count: gemCount.bonus,
    color: color.green500,
    text: gemCount.bonusText
  }, {
    key: 'promo',
    count: gemCount.promo,
    color: color.purple400,
    text: gemCount.promoText
  }];
  const priceText = getProductPriceTextWithCurrency(gemProduct);
  return <ProductItem data-amount={gemCount.num} data-testid={'Payments-GemProduct'} onClick={handleClickProduct} data-sentry-element="ProductItem" data-sentry-component="GemProductItem" data-sentry-source-file="GemProductItem.tsx">
      <ProductImageWrapper backgroundColor={gemProduct.backgroundColor} data-sentry-element="ProductImageWrapper" data-sentry-source-file="GemProductItem.tsx">
        <ProductImage src={gemProduct.iconUrl} alt='gemProduct' data-sentry-element="ProductImage" data-sentry-source-file="GemProductItem.tsx" />
      </ProductImageWrapper>
      <ProductLabelWrapper data-sentry-element="ProductLabelWrapper" data-sentry-source-file="GemProductItem.tsx">
        <GemAmountWrapper data-sentry-element="GemAmountWrapper" data-sentry-source-file="GemProductItem.tsx">
          <ProductNameText data-sentry-element="ProductNameText" data-sentry-source-file="GemProductItem.tsx">{`${gemCount.baseText} ${t('common__gems')}`}</ProductNameText>
          {bonusGemAmounts.filter(v => v.count).map(item => <BonusGemAmountText key={item.key} colorCode={item.color}>
                {`+ ${item.text} ${t('common__gems')}`}
              </BonusGemAmountText>)}
        </GemAmountWrapper>
        <ProductPriceWrap data-sentry-element="ProductPriceWrap" data-sentry-source-file="GemProductItem.tsx">
          {enableWebLowPrice ? <>
              <OriginalPrice>
                {getProductPriceTextWithCurrency({
              ...gemProduct,
              price: priceBeforeWebDiscount
            })}
              </OriginalPrice>
              <DiscountPriceInfo>
                <DiscountPercentText>{`${WEB_DISCOUNT_RATE * 100}%`}</DiscountPercentText>
                <PriceText>{priceText}</PriceText>
              </DiscountPriceInfo>
            </> : <PriceText>{priceText}</PriceText>}
        </ProductPriceWrap>
      </ProductLabelWrapper>
    </ProductItem>;
};
export default GemProductItem;