import { useAtomValue, useSetAtom } from 'jotai';
import { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import ProductNudgingModalLayout, { ProductNudgingModalLayoutProps, ProductNudgingModalLoading } from 'src/components/ProductNudgingModal';
import { isStarterPackageBannerShownAtom } from 'src/stores/shop/atoms';
import { SHOP_ORIGIN, ShopOrigin } from 'src/stores/shop/types';
import { StarterPackageModule, RefundPolicyModule, SingleProductModule } from 'src/types/gemLack';
import { StrictUnion } from 'src/types/utils';
import StarterPackageNudging from './StarterPackageNudging';
import useEvent from 'src/hooks/useEvent';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import useSelectedTab from 'src/hooks/useSelectedTab';
import { STATUS } from 'src/types/Match';
import { statusAtom } from 'src/stores/match/atoms';
import { useRouter } from 'next/router';
import { useReportPopupViewedMutation } from 'src/hooks/usePopupQuery';
interface BaseProps {
  description: string;
  origins?: ShopOrigin[];
}
interface LoadingStateProps extends BaseProps {
  isLoading: true;
}
interface LoadedStateProps extends BaseProps {
  isLoading?: false;
  starterPackageModule: StarterPackageModule;
  singleProductModule: SingleProductModule;
  refundPolicyModule?: RefundPolicyModule;
}
type Props = ProductNudgingModalLayoutProps & {
  description: string;
  origins?: ShopOrigin[];
  isMirrorEnterNudging?: boolean;
} & PropsWithChildren<StrictUnion<LoadingStateProps | LoadedStateProps>>;
const DEFAULT_ORIGINS = [SHOP_ORIGIN.starterPackageNudge];
const StarterPackageNudgingModal: FC<Props> = ({
  isLoading,
  children,
  description,
  origins = DEFAULT_ORIGINS,
  starterPackageModule,
  singleProductModule,
  refundPolicyModule,
  onClose,
  isMirrorEnterNudging = false,
  ...modalLayoutProps
}) => {
  const status = useAtomValue(statusAtom);
  const setIsStarterPackageBannerShown = useSetAtom(isStarterPackageBannerShownAtom);
  const {
    selectedEventTab
  } = useSelectedTab();
  const router = useRouter();
  const {
    mutate
  } = useReportPopupViewedMutation();
  const eventPage = useMemo(() => {
    if (status !== STATUS.INITIAL) return 'finding';
    return router.pathname.includes('host') ? 'room' : 'main';
  }, [router.pathname, status]);
  const pushEvent = useEvent();
  const handleCloseModal = () => {
    pushEvent({
      eventType: EVENT_TYPE.POPUP,
      eventName: EVENT_NAME.STARTER_PACKAGE_POPUP_V1_CLOSE,
      eventParams: {
        action_category: 'click',
        tab: selectedEventTab,
        page: eventPage,
        target: 'starterpackage_popup',
        initial_feature: origins
      }
    });
    onClose?.();
  };
  useEffect(() => {
    /**
     * 미러 진입에 의한 넛징은 1회만 노출되도록 서버에 노출 사실을 전달
     */
    if (isMirrorEnterNudging) {
      mutate({
        popupType: 'WEB_STARTER_PACKAGE'
      });
    }
  }, [mutate, isMirrorEnterNudging]);
  useEffect(() => {
    setIsStarterPackageBannerShown(true);
  }, [setIsStarterPackageBannerShown]);
  return <ProductNudgingModalLayout {...modalLayoutProps} onClose={handleCloseModal} data-sentry-element="ProductNudgingModalLayout" data-sentry-component="StarterPackageNudgingModal" data-sentry-source-file="index.tsx">
      {isLoading ? <ProductNudgingModalLoading /> : <StarterPackageNudging description={description} singleProductModule={singleProductModule} starterPackageModule={starterPackageModule} refundPolicyModule={refundPolicyModule} origins={origins} />}
    </ProductNudgingModalLayout>;
};
export default StarterPackageNudgingModal;