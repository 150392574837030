import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import {
  contentsMatchConfigAtom,
  handleDisconnectAtom,
} from 'src/stores/match/atoms';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { isPurchasedStarterPackageAtom } from 'src/stores/payments/atoms';
import { GemLackPopupModuleType } from 'src/types/gemLack';
import {
  PurchaseCompleteMessage,
  WindowMessage,
  WindowMessageType,
} from 'src/types/message';
import { ModalType } from 'src/types/Modal';

/**
 * 자체적으로 구현한 어플케이션 내 message event 처리
 */
const useCustomWindowMessageEvent = () => {
  const contentsMatchConfig = useAtomValue(contentsMatchConfigAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const handleDisconnect = useSetAtom(handleDisconnectAtom);
  const setIsPurchasedStarterPackage = useSetAtom(
    isPurchasedStarterPackageAtom
  );

  const handlePurchaseCompleteMessage = useCallback(
    (message: PurchaseCompleteMessage) => {
      const { productModuleType } = message;

      if (
        productModuleType === GemLackPopupModuleType.STARTER_PACKAGE_PRODUCT
      ) {
        setIsPurchasedStarterPackage(true);
      }

      // 컨텐츠 매치를 통한 스타터패키지 넛징이었을 경우 구매 완료 시점에 자동으로 컨텐츠 매치 종료
      if (
        contentsMatchConfig?.contentsMatchMediaInfo.mediaType ===
        'STARTER_PACKAGE_NUDGE'
      ) {
        closeModal(ModalType.STARTER_PACKAGE_NUDGING);
        handleDisconnect({
          reconnect: true,
          finisher: true,
        });
      }
    },
    [
      closeModal,
      contentsMatchConfig?.contentsMatchMediaInfo.mediaType,
      handleDisconnect,
      setIsPurchasedStarterPackage,
    ]
  );

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const message = event.data;
      if (!message?.type) return;

      const customWindowMessage = message as WindowMessage;

      switch (customWindowMessage.type) {
        case WindowMessageType.PurchaseComplete:
          handlePurchaseCompleteMessage(customWindowMessage);
          break;
        default:
          break;
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handlePurchaseCompleteMessage]);
};

export default useCustomWindowMessageEvent;
