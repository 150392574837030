const isProd = process.env.NEXT_PUBLIC_ENVIRONMENT === 'Prod';
const isWebview = typeof window !== 'undefined' && !!window.AzarJs;
function getDefaultApiURL() {
  if (isProd) {
    return 'https://api.azarlive.com';
  }

  if (
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'Qa' ||
    process.env.NEXT_PUBLIC_USE_QA_SERVER_IN_DEV === 'true'
  ) {
    return 'https://api.dev.an1.azarlive.hpcnt.com';
  }

  return `https://${process.env.NEXT_PUBLIC_NAMESPACE}-api.kube-dev-an1.azarlive.com`;
}

// Spinnaker 값을 먼저 사용하고, 없으면 환경별 기본값 사용
// prod & webview인 경우 우회주소 사용
const ENV_REST_API_ORIGIN =
  isWebview && isProd
    ? process.env.NEXT_PUBLIC_BYPASS_REST_API_ORIGIN
    : process.env.NEXT_PUBLIC_REST_API_ORIGIN;

export const REST_API_HOST = ENV_REST_API_ORIGIN || getDefaultApiURL();

export const RPC_API_HOST =
  process.env.NEXT_PUBLIC_RPC_API_ORIGIN || getDefaultApiURL();
