export enum TabName {
  Mirror = 'MIRROR',
  Match = 'MATCH',
  Live = 'LIVE',
  About = 'ABOUT',
  History = 'HISTORY',
  Blog = 'BLOG',
}

export interface SelectTabRequest {
  tabName: TabName;
}
