import { useRestMutation } from 'src/hooks/useRestMutation';
import { SelectTabRequest, TabName } from './types';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { statusAtom } from 'src/stores/match/atoms';
import { STATUS } from 'src/types/Match';

export enum TabApiList {
  SelectTab = '/api/v1/select-tab',
}

const useSelectedTab = () => {
  const router = useRouter();
  const status = useAtomValue(statusAtom);

  const { mutate } = useRestMutation<SelectTabRequest>({
    path: TabApiList.SelectTab,
    method: 'post',
  });

  const selectedTab = useMemo(() => {
    if (router.pathname === '/') {
      return status === STATUS.INITIAL ? TabName.Mirror : TabName.Match;
    }
    if (router.pathname.includes('live')) return TabName.Live;
    if (router.pathname === '/about') return TabName.About;
    if (router.pathname === '/history') return TabName.History;
    if (router.pathname.includes('blog')) return TabName.Blog;

    return null;
  }, [router.pathname, status]);

  /**
   * 이벤트 로깅 기준 현재 탭 정보
   */
  const selectedEventTab = useMemo(() => {
    switch (selectedTab) {
      case TabName.Mirror:
      case TabName.Match:
        return 'mirror';
      case TabName.Live:
        return 'live';
      case TabName.About:
        return 'about';
      case TabName.Blog:
        return 'blog';
      case TabName.History:
        return 'history';
      default:
        return router.pathname;
    }
  }, [selectedTab, router.pathname]);

  /**
   * 서버에 현재 탭을 전송
   * 현재 탭 관련하여 필요한 동작이 있는 경우 서버에서 브로커 메시지로 정보 전달
   */
  const reportSelectedTab = useCallback(() => {
    if (!selectedTab) return;

    mutate({
      tabName: selectedTab,
    });
  }, [mutate, selectedTab]);

  return {
    selectedTab,
    reportSelectedTab,
    selectedEventTab,
  };
};

export default useSelectedTab;
