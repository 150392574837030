import { FC, useCallback, useEffect, useMemo } from 'react';
import { useGetStarterPackagePopupQuery } from 'src/hooks/useContentsMatchQuery';
import { useSetAtom } from 'jotai';
import { handleContentsMatchStartAtom, handleDisconnectAtom } from 'src/stores/match/atoms';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
import { useTranslation } from 'react-i18next';
import StarterPackageNudgingModal from '.';
import { useQueryClient } from '@tanstack/react-query';
import { ShopApiList } from 'src/stores/shop/apis';
const MatchStarterPackageNudgingModal: FC = () => {
  const {
    isLoading,
    data,
    isSuccess
  } = useGetStarterPackagePopupQuery();
  const handleContentsMatchStart = useSetAtom(handleContentsMatchStartAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const handleDisconnect = useSetAtom(handleDisconnectAtom);
  const {
    t
  } = useTranslation();
  const queryClient = useQueryClient();
  const onClose = useCallback(() => {
    closeModal(ModalType.STARTER_PACKAGE_NUDGING);
    handleDisconnect({
      reconnect: true,
      finisher: true
    });
  }, [closeModal, handleDisconnect]);
  const starterPackagePopupPage = useMemo(() => data?.data.result.popup, [data]);
  useEffect(() => {
    if (!isSuccess) return;
    if (starterPackagePopupPage) {
      handleContentsMatchStart();
      // 스타터패키지 넛징 배너 노출 유도
      queryClient.invalidateQueries([ShopApiList.GetShopEntryBanner]);
    } else {
      // 스타터패키지 대상이 아닌 경우 (실제 발생 가능하지 않은 엣지 케이스)
      onClose();
    }
  }, [isSuccess, handleContentsMatchStart, starterPackagePopupPage, onClose, queryClient]);
  const isLoadingStarterPackage = isLoading || !starterPackagePopupPage;
  return <StarterPackageNudgingModal onClose={onClose} description={t('popup_web_starterpackage_desc')} {...isLoadingStarterPackage ? {
    isLoading: true
  } : {
    singleProductModule: starterPackagePopupPage.sections[0].modules[0],
    starterPackageModule: starterPackagePopupPage.sections[0].modules[1],
    refundPolicyModule: starterPackagePopupPage.sections[1].modules?.[0]
  }} data-sentry-element="StarterPackageNudgingModal" data-sentry-component="MatchStarterPackageNudgingModal" data-sentry-source-file="MatchStarterPackageNudgingModal.tsx" />;
};
export default MatchStarterPackageNudgingModal;