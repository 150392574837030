import { useEffect, useState } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';

import {
  pushChannelNameAtom,
  stompBrokerInfoAtom,
} from 'src/stores/auth/atoms';
import {
  closeStompAtom,
  handleStompMessageAtom,
  setStompAtom,
  stompAtom,
} from 'src/stores/stomp/atoms';

export default function useStompSubscriber() {
  const stomp = useAtomValue(stompAtom);
  const setStomp = useSetAtom(setStompAtom);
  const pushChannelName = useAtomValue(pushChannelNameAtom);
  const handleStompMessage = useSetAtom(handleStompMessageAtom);
  const closeStomp = useSetAtom(closeStompAtom);
  const [isStompReady, setIsStompReady] = useState(false);
  const stompBrokerInfo = useAtomValue(stompBrokerInfoAtom);

  useEffect(() => {
    if (stompBrokerInfo) {
      setStomp(stompBrokerInfo);
    }
  }, [setStomp, stompBrokerInfo]);

  useEffect(
    () => () => {
      closeStomp();
    },
    [closeStomp]
  );

  useEffect(() => {
    let isCancelled = false;
    if (pushChannelName && stomp) {
      stomp
        .subscribe({
          channelId: pushChannelName,
          onMessage: handleStompMessage,
        })
        .then((subscription) => {
          if (!subscription) return;

          if (isCancelled) {
            stomp.unsubscribe(pushChannelName);
          } else {
            setIsStompReady(true);
          }
        });
      return () => {
        isCancelled = true;
        stomp.unsubscribe(pushChannelName);
        setIsStompReady(false);
      };
    }

    setIsStompReady(false);
  }, [pushChannelName, handleStompMessage, stomp]);

  return {
    isStompReady,
  };
}
