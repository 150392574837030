import { FC } from 'react';
import styled from '@emotion/styled';
import { useAtomValue, useSetAtom } from 'jotai';
import ModalLayoutBase, { MobileModalType, ModalHeader, ModalInner } from 'src/components/ModalLayout';
import { backStepAtom, isRegisterLoadingAtom, socialRegisterStepsAtom, socialRegisterStepNumberAtom } from 'src/stores/register/atoms';
import { exitLoginModalAtom, loginModalHeaderAtom } from 'src/stores/phoneNumberLogin/atoms';
import { ModalNavButtonType } from 'src/types/Modal';
import { LoadingBackground } from './styles';
import LoadingIndicator from 'src/components/LoadingIndicator';
import { useGetConsents } from 'src/hooks/useGetConsents';
import { closeAllModalAtom } from 'src/stores/modal/atoms';
const ModalLayout = styled(ModalLayoutBase)<{
  minHeight?: string;
}>`
  width: 500px;

  ${({
  minHeight
}) => minHeight ? `
      min-height: ${minHeight};
      height: auto;

      & > ${ModalInner} {
        min-height: inherit;
      }
  ` : `
    height: 600px;
  `}
`;
const SocialRegister: FC = () => {
  const Steps = useAtomValue(socialRegisterStepsAtom);
  const step = useAtomValue(socialRegisterStepNumberAtom);
  const backStep = useSetAtom(backStepAtom);
  const loginModalHeader = useAtomValue(loginModalHeaderAtom);
  const isRegisterLoading = useAtomValue(isRegisterLoadingAtom);
  const exitLoginModal = useSetAtom(exitLoginModalAtom);
  const closeModal = useSetAtom(closeAllModalAtom);
  const {
    data: consentsData
  } = useGetConsents();
  const {
    uiType: consentsUiType
  } = consentsData?.data?.result || {};
  const Step = Steps[step - 1];
  const handleBack = () => {
    backStep();
    if (step === 2 && consentsUiType === 'CONSENT_WEB_B') {
      backStep();
    }
  };
  const handleCloseModal = () => {
    exitLoginModal();
    closeModal();
  };
  return <ModalLayout onBack={loginModalHeader?.onBack || handleBack} navButtonType={ModalNavButtonType.BACK} mobileType={MobileModalType.SCREEN_MODAL} isDim closeWhenClickOutside={!isRegisterLoading} minHeight={step === 1 ? '600px' : undefined} onClose={handleCloseModal} data-sentry-element="ModalLayout" data-sentry-component="SocialRegister" data-sentry-source-file="SocialRegister.tsx">
      {loginModalHeader?.component || <ModalHeader />}
      {isRegisterLoading && <LoadingBackground>
          <LoadingIndicator size={24} />
        </LoadingBackground>}

      <Step data-sentry-element="Step" data-sentry-source-file="SocialRegister.tsx" />
    </ModalLayout>;
};
export default SocialRegister;