import { useEffect } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { remoteConfigAtom, userDataAtom } from 'src/stores/auth/atoms';
import { getUpdatedUserInfoAtom } from 'src/stores/user/atom';

const DEFAULT_REFRESH_USER_INFO_INTERVAL_SEC = 14400; // 4시간

/**
 * 웹은 토큰 방식으로 인해 로그인 주기가 길기 때문에 주기적으로 유저 정보를 최신화하는 훅
 */
export default function useUpdateUserInfo() {
  const remoteConfig = useAtomValue(remoteConfigAtom);
  const userData = useAtomValue(userDataAtom);
  const getUpdatedUserInfo = useSetAtom(getUpdatedUserInfoAtom);

  useEffect(() => {
    if (!userData?.userId) return;
    getUpdatedUserInfo();
    const timer = setInterval(
      () => {
        getUpdatedUserInfo();
      },
      (remoteConfig?.refreshUserInfoIntervalInSeconds ??
        DEFAULT_REFRESH_USER_INFO_INTERVAL_SEC) * 1000
    );
    return () => {
      clearInterval(timer);
    };
  }, [
    getUpdatedUserInfo,
    remoteConfig?.refreshUserInfoIntervalInSeconds,
    userData?.userId,
  ]);
}
