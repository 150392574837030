import { FC } from 'react';
import styled from '@emotion/styled';
import { MobileModalType, ModalHeader, ModalHeaderDescriptionRow as ModalHeaderDescBase, ModalHeaderTitle } from 'src/components/ModalLayout';
const ModalHeaderDescriptionRow = styled(ModalHeaderDescBase)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`;
interface Props {
  title: string;
  description: string;
}
const Header: FC<Props> = ({
  title,
  description
}) => {
  return <ModalHeader data-sentry-element="ModalHeader" data-sentry-component="Header" data-sentry-source-file="Header.tsx">
      <ModalHeaderTitle mobileType={MobileModalType.BOTTOM_SHEET} data-sentry-element="ModalHeaderTitle" data-sentry-source-file="Header.tsx">
        {title}
      </ModalHeaderTitle>
      <ModalHeaderDescriptionRow data-sentry-element="ModalHeaderDescriptionRow" data-sentry-source-file="Header.tsx">{description}</ModalHeaderDescriptionRow>
    </ModalHeader>;
};
export default Header;