import { FC, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import ExternalLink from 'src/components/ExternalLink';
import Gap from 'src/components/Gap';
import LoadingIndicator from 'src/components/LoadingIndicator';
import { MobileModalType, ModalContent as ModalContentBase, ModalHeader, ModalHeaderTitle } from 'src/components/ModalLayout';
import { RootStyle } from 'src/components/Payments/style';
import useEnableRemoteConfig from 'src/hooks/useEnableRemoteConfig';
import useEvent from 'src/hooks/useEvent';
import useGemAmount from 'src/hooks/useGemAmount';
import useGemProduct from 'src/hooks/useGemProduct';
import useLogin from 'src/hooks/useLogin';
import useTermsInfo from 'src/hooks/useTermsInfo';
import { getGemProductsFailedAtom, resetGemProductsAtom } from 'src/stores/payments/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { PgPaymentMethod } from 'src/types/Payments';
import { numComma } from 'src/utils/common';
import { getPromotion } from 'src/utils/payments';
import EmptyProducts from './EmptyProducts';
import ProductLoadingFailure from './ProductLoadingFailure';
import { ProductList, ProductSection, ProductSectionDivider, PromoIcon, PromoRate, PromotionRate, PromotionTextWrap, PromotionTotalRate, PromoTitle, PromoWrapper } from './style';
import WebLowPricePromoTop from './WebLowPricePromoTop';
import GemImage from 'src/components/GemImage';
import { useGetTermsUrl } from 'src/hooks/useGetTermsUrl';
import { useGetStarterPackagePopupQuery } from 'src/hooks/useContentsMatchQuery';
import GemProductItem from './ProductItem/GemProductItem';
import StarterPackageItem from './ProductItem/StarterPackageItem';
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const MyGems = styled.div`
  display: flex;
  color: ${({
  theme
}) => theme.color.gray900__dkGray970};
  font-size: 16px;
  align-items: center;
  gap: 5px;
`;
const DescWrapper = styled.ul`
  margin: 0;
  padding: 18px 0 0 24px;
`;
const LegalDesc = styled.li`
  font-size: 13px;
  color: ${({
  theme
}) => theme.color.gray400__dkGray300};
  margin: 0px;
  padding: 0px;
  & + & {
    margin-top: 10px;
  }
`;
const LegalLink = styled(ExternalLink)`
  color: inherit;
`;
const EmptyListDesc = styled.div`
  position: relative;
  color: ${({
  theme
}) => theme.color.gray900__dkGray970};
  font-size: 16px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  height: 301px;
  ${({
  theme
}) => theme.screenSize.mobile} {
    flex: 1;
  }
`;
const ImgGemSquare = styled(GemImage)`
  width: 20px;
  height: 20px;
`;
const ModalContent = styled(ModalContentBase)`
  padding-top: 16px;
  padding-bottom: 24px;
`;
const ShopComponent: FC = () => {
  const {
    t
  } = useTranslation();
  const gemAmount = useGemAmount();
  const {
    isLogin
  } = useLogin();
  const gemProductResponse = useGemProduct();
  const gemProducts = gemProductResponse?.gemProducts;
  const paymentMethod = gemProductResponse?.paymentMethod;
  const bonusGemRates = gemProductResponse?.bonusGemRates;
  const {
    isGDPR
  } = useTermsInfo();
  const {
    termOfUse
  } = useGetTermsUrl();
  const pushEvent = useEvent();
  const [getGemProductsFailed, setGetGemProductsFailed] = useAtom(getGemProductsFailedAtom);
  const resetGemProducts = useSetAtom(resetGemProductsAtom);
  const {
    isLoading: isLoadingStarterPackagePopup,
    data
  } = useGetStarterPackagePopupQuery({
    refetchOnMount: true
  });
  useEffect(() => {
    if (isLogin && gemProducts) {
      pushEvent({
        eventType: EVENT_TYPE.PURCHASE,
        eventName: EVENT_NAME.SHOP_OPEN
      });
    }
  }, [isLogin, pushEvent, gemProducts]);
  useEffect(() => () => {
    // 상점 모달 닫으면 gemProducts 초기화해서 매번 상품 리스트 새로 불러오도록
    setGetGemProductsFailed(false);
    resetGemProducts();
  }, [resetGemProducts, setGetGemProductsFailed]);
  const bonusGemRateText = useMemo(() => bonusGemRates?.map(({
    type,
    rate,
    vipRating
  }) => {
    switch (type) {
      case 'PG_BONUS':
        {
          return t('web_bonus_pg', {
            bonusPercentage: rate
          });
        }
      case 'VIP_BONUS':
        {
          return t('web_banner_benefit_vip', {
            n: rate,
            currentVipLevel: vipRating
          });
        }
      case 'BONUS_COUPON':
        {
          return t('web_bonus_coupon', {
            bonusPercentage: rate
          });
        }
    }
  }), [bonusGemRates, t]);
  const enableWebLowPrice = useEnableRemoteConfig('enableWebLowPrice');
  if (!isLogin) {
    return <RootStyle />;
  }
  const {
    isPromotion,
    promoPercent,
    promoTotalPercent
  } = getPromotion(gemProducts);
  const promoRate = bonusGemRateText?.join(' + ');
  const promotionRate = `+ ${t('web_bonus_promo', {
    bonusPercentage: promoPercent
  })}`;
  const starterPackagePopupPage = data?.data.result.popup;
  const getShopContent = () => {
    if (getGemProductsFailed) {
      return <ProductLoadingFailure />;
    }
    if (gemProductResponse === null || isLoadingStarterPackagePopup) {
      return <EmptyListDesc>
          <LoadingIndicator size={25} data-testid='Shop-Loading' />
        </EmptyListDesc>;
    }
    if (!gemProducts?.length) {
      return <EmptyProducts />;
    }
    return <ModalContent data-sentry-element="ModalContent" data-sentry-component="getShopContent" data-sentry-source-file="index.tsx">
        {enableWebLowPrice ? <WebLowPricePromoTop promotionRate={promotionRate} isPromotion={isPromotion} bonusGemRates={bonusGemRates} /> : <PromoWrapper>
            <PromoTitle>
              <PromoIcon src='/images/icons/imgGift.png' alt='Gift for web users' />
              <Gap width={6} />
              <span>
                {t(isPromotion ? 'web_promo_title' : 'web_bonus_title')}
              </span>
            </PromoTitle>
            <PromotionTextWrap>
              <PromoRate>{promoRate}</PromoRate>
              {isPromotion && <PromotionRate>{promotionRate}</PromotionRate>}
            </PromotionTextWrap>
            <PromotionTotalRate>
              {t('web_bonus_total', {
            bonusPercentage: promoTotalPercent
          })}
            </PromotionTotalRate>
          </PromoWrapper>}
        <ProductSection data-sentry-element="ProductSection" data-sentry-source-file="index.tsx">
          {starterPackagePopupPage && <>
              <ProductList>
                <StarterPackageItem starterPackagePopupPage={starterPackagePopupPage} />
              </ProductList>
              <ProductSectionDivider />
            </>}
          <ProductList data-sentry-element="ProductList" data-sentry-source-file="index.tsx">
            {gemProducts.map(gemProduct => <GemProductItem gemProduct={gemProduct} key={gemProduct.productId} />)}
          </ProductList>
        </ProductSection>
        <DescWrapper data-sentry-element="DescWrapper" data-sentry-source-file="index.tsx">
          <LegalDesc data-sentry-element="LegalDesc" data-sentry-source-file="index.tsx">{t('shop_bottom_notice')}</LegalDesc>
          {paymentMethod === PgPaymentMethod.TOSS && <>
              <LegalDesc>{t('shop_refund_explanation_kr')}</LegalDesc>
              <LegalDesc>
                <LegalLink href='https://azarlive.com/home/ko-KR/refundPolicy.html'>
                  {t('shop_refund_minor_explanation_kr')}
                </LegalLink>
              </LegalDesc>
            </>}
          {isGDPR && <>
              <LegalDesc>{t('shop_vat_explanation_eu')}</LegalDesc>
              <LegalDesc>{t('shop_refund_explanation_eu')}</LegalDesc>
              <LegalDesc>
                <LegalLink href={termOfUse}>
                  {t('shop_more_information_eu')}
                </LegalLink>
              </LegalDesc>
            </>}
        </DescWrapper>
      </ModalContent>;
  };
  return <>
      <ModalHeader data-sentry-element="ModalHeader" data-sentry-source-file="index.tsx">
        <TitleWrapper data-sentry-element="TitleWrapper" data-sentry-source-file="index.tsx">
          <ModalHeaderTitle mobileType={MobileModalType.SCREEN_MODAL} data-sentry-element="ModalHeaderTitle" data-sentry-source-file="index.tsx">
            {t('shop_title')}
          </ModalHeaderTitle>
          <MyGems data-sentry-element="MyGems" data-sentry-source-file="index.tsx">
            <ImgGemSquare data-sentry-element="ImgGemSquare" data-sentry-source-file="index.tsx" />
            {numComma(gemAmount || 0)}
          </MyGems>
        </TitleWrapper>
      </ModalHeader>
      {getShopContent()}
    </>;
};
export default ShopComponent;