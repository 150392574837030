import { FC, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { GemLackTrigger, GemProductModule, RefundPolicyModule } from 'src/types/gemLack';
import useEvent from 'src/hooks/useEvent';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { useSetAtom } from 'jotai';
import { closeModalAtom, showModalAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
import ShopModal from 'src/components/Shop/ShopModal';
import { ProductNudgingModalContent, ProductNudgingModalHeader, ProductNudgingRefundPolicyText } from 'src/components/ProductNudgingModal';
import { getSaveRateByGemProductComparison } from 'src/utils/payments';
import { ModalFooter as ModalFooterBase } from 'src/components/ModalLayout';
import Button from 'src/components/Button';
import GemLackMobileFooter from './GemLackMobileFooter';
import { useTranslation } from 'react-i18next';
import NormalGemProduct from './NormalGemProduct';
const ModalFooter = styled(ModalFooterBase)`
  border-top: 1px solid ${({
  theme
}) => theme.color.gray100__dkGray150};
  ${({
  theme
}) => theme.screenSize.mobile} {
    padding: 0;
  }
`;
const DesktopButton = styled(Button)`
  ${({
  theme
}) => theme.screenSize.mobile} {
    display: none;
  }
`;
const ProductsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;
`;
interface Props {
  title: string;
  description: string;
  gemProductModules: [GemProductModule, GemProductModule];
  refundPolicyModule?: RefundPolicyModule;
  gemLackTriggers: GemLackTrigger[];
}
const NormalGemLackNudging: FC<Props> = ({
  title,
  description,
  gemProductModules: [cheaperProduct, expensiveProduct],
  refundPolicyModule,
  gemLackTriggers
}) => {
  const pushEvent = useEvent();
  const showModal = useSetAtom(showModalAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const {
    t
  } = useTranslation();
  const savePercent = useMemo(() => {
    return getSaveRateByGemProductComparison(expensiveProduct, cheaperProduct);
  }, [cheaperProduct, expensiveProduct]);
  const handleShopButtonClick = () => {
    closeModal(ModalType.GEM_LACK);
    showModal({
      key: ModalType.SHOP,
      component: ShopModal
    });
  };
  useEffect(() => {
    pushEvent({
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME.GEM_LACK_POPUP__IMPRESSION
    });
  }, [pushEvent]);
  return <>
      <ProductNudgingModalHeader title={title} description={description} data-sentry-element="ProductNudgingModalHeader" data-sentry-source-file="NormalGemLackNudging.tsx" />
      <ProductNudgingModalContent data-sentry-element="ProductNudgingModalContent" data-sentry-source-file="NormalGemLackNudging.tsx">
        <ProductsContainer data-sentry-element="ProductsContainer" data-sentry-source-file="NormalGemLackNudging.tsx">
          <NormalGemProduct gemProductModule={cheaperProduct} gemLackTriggers={gemLackTriggers} data-sentry-element="NormalGemProduct" data-sentry-source-file="NormalGemLackNudging.tsx" />
          <NormalGemProduct gemProductModule={expensiveProduct} isMoreExpensive // 서버에서 두 번째 상품이 더 비싸도록 보장해서 내려줌
        savePercent={savePercent} gemLackTriggers={gemLackTriggers} data-sentry-element="NormalGemProduct" data-sentry-source-file="NormalGemLackNudging.tsx" />
        </ProductsContainer>
        {refundPolicyModule && <ProductNudgingRefundPolicyText refundPolicyModule={refundPolicyModule} />}
      </ProductNudgingModalContent>
      <ModalFooter data-sentry-element="ModalFooter" data-sentry-source-file="NormalGemLackNudging.tsx">
        <DesktopButton color='primary' onClick={handleShopButtonClick} id='GEM_LACK_POPUP_BUTTON' variant='text' data-sentry-element="DesktopButton" data-sentry-source-file="NormalGemLackNudging.tsx">
          {t('FILTER_GENDER_SHOP_BUTTON')}
        </DesktopButton>
        <GemLackMobileFooter data-sentry-element="GemLackMobileFooter" data-sentry-source-file="NormalGemLackNudging.tsx" />
      </ModalFooter>
    </>;
};
export default NormalGemLackNudging;