import { FC } from 'react';
import styled from '@emotion/styled';
import { useAtomValue, useSetAtom } from 'jotai';
import ModalLayoutBase, { MobileModalType, ModalHeader, ModalInner } from 'src/components/ModalLayout';
import { closeAllModalAtom } from 'src/stores/modal/atoms';
import { isRegisterLoadingAtom } from 'src/stores/register/atoms';
import { exitLoginModalAtom, loginModalHeaderAtom, phoneNumberLoginBackStepAtom, phoneNumberLoginStepNumberAtom, phoneNumberLoginStepsAtom } from 'src/stores/phoneNumberLogin/atoms';
import { ModalNavButtonType } from 'src/types/Modal';
import { useGetConsents } from 'src/hooks/useGetConsents';
import { LoadingBackground } from 'src/components/LoginModal/styles';
import LoadingIndicator from 'src/components/LoadingIndicator';
const ModalLayout = styled(ModalLayoutBase)<{
  minHeight?: string;
}>`
  width: 500px;

  ${({
  minHeight
}) => minHeight ? `
      min-height: ${minHeight};
      height: auto;

      & > ${ModalInner} {
        min-height: inherit;
      }
  ` : `
    height: 600px;
  `}
`;
const PhoneNumberLogin: FC = () => {
  const Steps = useAtomValue(phoneNumberLoginStepsAtom);
  const stepNumber = useAtomValue(phoneNumberLoginStepNumberAtom);
  const loginModalHeader = useAtomValue(loginModalHeaderAtom);
  const isRegisterLoading = useAtomValue(isRegisterLoadingAtom);
  const exitLoginModal = useSetAtom(exitLoginModalAtom);
  const {
    data: consentsData
  } = useGetConsents();
  const {
    uiType: consentsUiType
  } = consentsData?.data?.result || {};
  const Step = Steps[stepNumber - 1];
  const backStep = useSetAtom(phoneNumberLoginBackStepAtom);
  const closeModal = useSetAtom(closeAllModalAtom);
  const handleCloseModal = () => {
    exitLoginModal();
    closeModal();
  };
  const onBack = () => {
    backStep();
    if (stepNumber === 2 && consentsUiType === 'CONSENT_WEB_B') {
      backStep();
    }
  };
  return <ModalLayout onBack={onBack} navButtonType={stepNumber === 1 ? ModalNavButtonType.NONE : ModalNavButtonType.BACK} isDim closeWhenClickOutside={!isRegisterLoading} onClose={handleCloseModal} mobileType={MobileModalType.SCREEN_MODAL} minHeight={stepNumber === 1 ? '600px' : undefined} data-sentry-element="ModalLayout" data-sentry-component="PhoneNumberLogin" data-sentry-source-file="index.tsx">
      {loginModalHeader?.component || <ModalHeader />}
      {isRegisterLoading && <LoadingBackground>
          <LoadingIndicator size={24} />
        </LoadingBackground>}
      <Step data-sentry-element="Step" data-sentry-source-file="index.tsx" />
    </ModalLayout>;
};
export default PhoneNumberLogin;