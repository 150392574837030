export const getRandomElement = <T>(arr: T[]): T => {
  return arr[Math.floor(Math.random() * arr.length)];
};

/**
 * 주어진 배열 내에서 특정 요소가 맨 앞으로 위치하도록 정렬 (원본 변경 X)
 */
export const prioritizeElementInArray = <T>(
  arr: T[],
  prioritizedElement: T
) => {
  return [...arr].sort((a, b) => {
    if (a === prioritizedElement) return -1;
    if (b === prioritizedElement) return 1;
    return 0;
  });
};
