import { ImageLoaderProps } from 'next/image';
import { screenWidth } from 'src/styles/screenSize';

export const parseSizes = (sizes: string | null): number[] => {
  if (!sizes) {
    sizes = `(max-width: ${screenWidth.mobile}px) 50px, (max-width: ${screenWidth.tablet}px) ${screenWidth.tablet}px, ${screenWidth.desktop}px`;
  }

  const sizeValues: Set<number> = new Set();

  // 정규 표현식을 사용하여 (max-width: Xpx) Yvw 패턴을 찾고 처리
  const vwRegex = /\(max-width:\s*(\d+)px\)\s*(\d+)vw/g;
  let match: RegExpExecArray | null;
  while ((match = vwRegex.exec(sizes)) !== null) {
    const maxWidth = parseInt(match[1], 10);
    const vwPercentage = parseInt(match[2], 10);

    // vw를 픽셀로 변환 (예: 100vw는 maxWidth와 동일)
    const baseSize = (vwPercentage / 100) * maxWidth;

    // 1x, 2x, 3x 크기 추가
    sizeValues.add(baseSize);
    sizeValues.add(baseSize * 2);
    sizeValues.add(baseSize * 3);
  }

  // 고정된 px 값을 찾고 추가
  const pxRegex = /(\d+)px/g;
  while ((match = pxRegex.exec(sizes)) !== null) {
    const pxSize = parseInt(match[1], 10);
    sizeValues.add(pxSize);
  }

  // 중복 제거 및 정렬
  return Array.from(sizeValues).sort((a, b) => a - b);
};

const supportsWebp = () => {
  if (typeof window === 'undefined') return false;
  const elem = document.createElement('canvas');
  if (elem.getContext && elem.getContext('2d')) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
};

const supportsAvif = () => {
  if (typeof window === 'undefined') return false;
  const elem = document.createElement('canvas');
  if (elem.getContext && elem.getContext('2d')) {
    // was able or not to get AVIF representation
    return elem.toDataURL('image/avif').indexOf('data:image/avif') === 0;
  }
  return false;
};

export type OptimizeImageLoaderType = ImageLoaderProps & {
  sizes: string | undefined;
};

export const optimizedImageLoader = ({
  src,
  width,
  sizes,
}: OptimizeImageLoaderType) => {
  if (process.env.NODE_ENV !== 'production') {
    return src;
  }
  const originSrcName = src.replace(/@\d+x/, '');
  let optimizedSrc = originSrcName;

  // AVIF 또는 WebP 지원 여부에 따라 최적화된 이미지 선택
  if (supportsAvif()) {
    optimizedSrc = originSrcName.replace(/\.(jpg|jpeg|png)$/, '.avif');
    optimizedSrc = optimizedSrc.replace(/^(.*\/)(.*)$/, `$1avif/$2`);
  } else if (supportsWebp()) {
    optimizedSrc = originSrcName.replace(/\.(jpg|jpeg|png)$/, '.webp');
    optimizedSrc = optimizedSrc.replace(/^(.*\/)(.*)$/, `$1webp/$2`);
  } else {
    if (width >= screenWidth.tablet) {
      return optimizedSrc.replace('.', '@3x.');
    } else if (width >= screenWidth.mobile) {
      return optimizedSrc.replace('.', '@2x.');
    }

    return `${optimizedSrc}`;
  }

  const parsedSizes = parseSizes(sizes || null);

  // width보다 작은 크기 중에서 가장 큰 크기를 찾기

  const pixelRatio =
    typeof window !== 'undefined' ? window.devicePixelRatio || 1 : 1;

  const closestSize = parsedSizes
    .filter((size) => size <= width)
    .reduce((max, size) => (size > max ? size : max), 0);

  // pixelRatio에 따라 적절한 크기 선택
  let finalSize;
  const validSizes = parsedSizes.filter((size) => size <= width * pixelRatio);

  if (pixelRatio >= 3) {
    finalSize =
      validSizes.find((size) => size >= width * 3) ||
      validSizes[validSizes.length - 1];
  } else if (pixelRatio === 2) {
    finalSize =
      validSizes.find((size) => size >= width * 2) ||
      validSizes[validSizes.length - 1];
  } else {
    // pixelRatio가 1인 경우, validSizes에서 가장 큰 크기를 선택
    finalSize = validSizes.length > 0 ? Math.max(...validSizes) : closestSize;
  }

  // 파일 이름에 finalSize 추가
  const fileName = optimizedSrc.replace(
    /(\.[a-z]+)$/,
    `-${Math.round(finalSize)}$1`
  );

  return `${fileName}?w=${width}&q=75`;
};
