import { refreshUserBalance } from '@hyperconnect/matata-starter-kit';
import { IMessage } from '@stomp/stompjs';
import { atom } from 'jotai';
import { switchMap } from 'rxjs';
import { setServersInfoAtom } from 'src/stores/auth/atoms';
import { getInventoryAtom, userInventoryAtom } from 'src/stores/auth/atoms';
import { isLiveInitializedAtom } from 'src/stores/live/atoms';
import { getVipRatingDetailsAtom } from 'src/stores/vip/atoms';
import { StompBrokerInfo } from 'src/types/AzarUser';
import { atomWithPipes } from 'src/utils/atom';
import { StompClient } from 'src/utils/stomp';
import { showModalAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
import StarterPackageNudgingModal from 'src/components/StarterPackageNudgingModal';
import i18next from 'i18next';
import { BrokerMessage } from 'src/types/brokerMessage';
import { queryClientAtom } from 'jotai-tanstack-query';
import { ShopApiList } from 'src/stores/shop/apis';
import { ContentsMatchApiList } from 'src/hooks/useContentsMatchQuery';
import { isPurchasedStarterPackageAtom } from 'src/stores/payments/atoms';
import { isStarterPackageBannerShownAtom } from 'src/stores/shop/atoms';
export const stompAtom = atom<StompClient | null>(null);
export const closeStompAtom = atom(null, (get, set) => {
  const stompClient = get(stompAtom);
  if (stompClient) {
    stompClient.close();
    set(stompAtom, null);
  }
});
export const setStompAtom = atomWithPipes(null, async (get, set, stompBrokerInfo: StompBrokerInfo) => {
  set(closeStompAtom);
  set(stompAtom, new StompClient(stompBrokerInfo, {
    onError: () => {
      set(setServersInfoAtom);
    }
  }));
}, [switchMap]);
export const handleStompMessageAtom = atom(null, (get, set, data: IMessage) => {
  if (!data.body) {
    return;
  }
  const body = JSON.parse(data.body) as BrokerMessage;
  switch (body.type) {
    case 'inventoryItemUpdated':
      {
        const queryClient = get(queryClientAtom);
        if (get(isPurchasedStarterPackageAtom)) {
          /**
           * 구매 가능 상품, 넛징 등의 갱신을 고려한 관련 API 캐시 제거
           * 서버 구현의 비동기성으로 인해 구매 로직 직후에는 관련 상태 갱신이 정확하지 않음 (타이밍 이슈)
           * -> 따라서 관련 상태가 명확히 갱신된 시점인 inventoryItemUpdated 브로커 메시지를 기준으로 활용
           */
          queryClient.invalidateQueries([ShopApiList.GetShopEntryBanner]);
          queryClient.invalidateQueries([ContentsMatchApiList.GetStarterPackagePopup]);
        }
        set(userInventoryAtom, body.inventoryItems);
        if (get(isLiveInitializedAtom)) {
          // 재화가 변경되었을 경우, WebApp에게 재화 변경을 알려줍니다.
          refreshUserBalance();
        }
        break;
      }
    case 'updateVipInfo':
      set(getInventoryAtom);
      set(getVipRatingDetailsAtom);
      break;
    case 'starterPackagePopup':
      {
        const queryClient = get(queryClientAtom);
        queryClient.invalidateQueries([ShopApiList.GetShopEntryBanner]);

        /**
         * 과도한 넛징 방지를 위해 동일 세션 내에서 스타터패키지 넛징을 노출한 적이 있는 경우 미러 진입 넛징을 하지 않음
         * 서버에서는 브로커 메시지가 날라오지만 클라에서 무시하는 방향으로 처리
         */
        if (get(isStarterPackageBannerShownAtom)) return;
        const pageBrokerMessage = body;
        const {
          singleProductModule,
          refundPolicyModule,
          packageProductModule: starterPackageModule
        } = pageBrokerMessage.sections[0];
        set(showModalAtom, {
          key: ModalType.STARTER_PACKAGE_NUDGING,
          component: () => <StarterPackageNudgingModal starterPackageModule={starterPackageModule} singleProductModule={singleProductModule} refundPolicyModule={refundPolicyModule} description={i18next.t('popup_web_starterpackage_desc')} isMirrorEnterNudging />
        });
        break;
      }
    default:
      break;
  }
});